import { useMutation, useQuery } from "@apollo/client"
import { CREATE_UPDATE_STAT_REPORTS_V2, GET_STAT_REPORT_V2 } from "apollo/queries/statsv2.queries"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts"
import { Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { TooltipSpan } from "components/reports"
import { mapStatDefsToStatsSelect, mapStatsReportsToStatSelect, mapStatsSelectToQuery, mapStatsSelectToReport } from "components/StatManager/v2/helpers"
import useStatsSelect from "components/StatManager/v2/hooks/useStatsSelect"
import StatsSelect from "components/StatManager/v2/statsselect"
import { theme } from "constants"
import { useUser } from 'helpers/user'
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { ExclamationTriangle } from "react-bootstrap-icons"
import styled from "styled-components"

const Header = styled.div({
  ...theme.typography.h5
})
const HeaderCaption = styled.div({
  ...theme.typography.body1
})
const BodyContainer = styled.div({
  maxHeight: '50vh',
  overflow: 'auto',
  scrollBehavior: 'smooth',
  padding: theme.spacing[2],
  paddingBottom: '70px'
})

export default function EditStatsModal({
  show,
  handleClose,
  handleFetch,
  refetchStats,
  mode, // statReport or statDefs
  statReport,
  statDefs: statDefsArg,
  statReportDetail,
}) {
  const {
    statDefs,
    statReportStats,
    statsSelectComponent,
    forceSetStatDefs
  } = useStatsSelect({
    defaultStats: statDefsArg,
    autoScrollPrompt: true
  })
  
  const { data, loading } = useQuery(GET_STAT_REPORT_V2, { variables: { slug: statReport }, skip: mode !== 'statReport' })
  const { user, isUserAdmin } = useUser();
  const [updateReport] = useMutation(CREATE_UPDATE_STAT_REPORTS_V2, {
    onCompleted: ({createUpdateStatReportV2: { statReportV2 }}) => {
      toastInfo(`Successfully updated ${statReportV2.name || statReportV2.slug}`)
      !!refetchStats && refetchStats()
    },
    onError: toastBddApiError
  })

  useEffect(() => {
    if (!!data && !loading) {
      // map stat defs to form stat select is expecting
      const mappedStatDefs = !!data ? mapStatsReportsToStatSelect(data.statReportV2.stats) : []
      forceSetStatDefs(mappedStatDefs)
    }
  }, [data]) //eslint-disable-line

  useEffect(() => {
    if (mode === 'statDefs') {
      forceSetStatDefs(mapStatDefsToStatsSelect(statDefsArg))
    }
  }, [mode]) //eslint-disable-line

  const handleClickFetch = () => {
    // map stat defs back to form expected by stat engine
    handleFetch(statDefs, statReportDetail);
    handleClose()
  }

  const handleClickSave = () => {
    if (!statReport) return
    updateReport({
      variables: { 
        input: {
          slug: statReport,
          stats: statReportStats
        }, 
        updateOnly: true, 
        createOnly: false
      }
    })
    handleClose()
  }

  const reportName = mode === 'statReport' && !!data ? data.statReportV2.name : statReport
  const canEditReport = statReportDetail?.isPublicEdit || isUserAdmin() || statReportDetail?.userId == user.id

  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <div>
        <Header>
          {mode === 'statReport' ? `Modify "${reportName}"`
          : `Edit stats`}
        </Header>
        <hr></hr>
        <HeaderCaption>
          Add or remove stats to explore more data.
          {' '}
          You can either save your changes in the report permanently, or fetch a temporary report (which you can save later).
        </HeaderCaption>
        <Typography variant='body1' style={{ fontStyle: 'italic', marginTop: '10px' }}>
          Drag and drop to re-order stats!
        </Typography>
      </div>
    </Modal.Header>
    <Modal.Body>
      <BodyContainer>
      {!statDefs ? <em>No stats found</em>
      : statsSelectComponent}
      </BodyContainer>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={handleClose}>
        Cancel
      </Button>
      {!!statReport && canEditReport && (
        <Button variant='warning' onClick={handleClickSave}>
          <TooltipSpan content={<Container>
            <ExclamationTriangle/> This will overwrite the existing report!
          </Container>}>
            Save Report
          </TooltipSpan>
        </Button>
      )}
      <Button variant='primary' onClick={handleClickFetch}>
        Fetch
      </Button>
    </Modal.Footer>
  </Modal>
}