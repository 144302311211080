import BDDStatTableV2 from 'components/bdd/Stats/BDDStatTableV2';
import { theme } from 'constants';
import styled from 'styled-components';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Column, Row } from 'components/bdd/Layout';
import { SLStatContext, StatCellVideoLinks, StatScatterChart } from '.';
import { useContext } from 'react';

const SL_TOURNAMENT_LEAGUE_SLUGS = [
  'WJC',
  'HG',
  'WJSS',
  'CTC',
  'IIHFU18',
  'WH18',
  'WH19',
  'IIHF',
  'WC',
  'WJC17'
]

const highlight = {
  boxShadow: `
    inset 0px 1px 0px 0px ${theme.colors.teams.bos.primary},
    inset 0px -1px 0px 0px ${theme.colors.teams.bos.primary}`,
};

const stickyPosition = {
  position: 'sticky',
  left: 0,
};

const TableStyles = styled.div({
  textAlign: 'center',
  paddingBottom: '10px',
  table: {
    marginBottom: '10px',
  },
  thead: {
    'tr:hover': {
      border: 0,
      boxShadow: 'none',
    },
    'th:first-child': {
      ...stickyPosition,
      backgroundColor: theme.colors.light.background,
    },
  },
  tfoot: {
    ...theme.borders.light,
    ...theme.borders.thin,
    ...theme.borders.top,
  },
  th: {
    ...theme.borders.light,
    ...theme.borders.thin,
    ...theme.borders.bottom,
    ...theme.typography.body1,
    padding: `0 ${theme.spacing[3]}px`,
    color: theme.colors.light.text.primary,
    userSelect: 'none',
    textAlign: 'center',
  },
  td: {
    ...theme.typography.body2,
    borderBottom: '1px solid #ddd',
    ':first-child': {
      paddingLeft: theme.spacing[2],
      paddingTop: theme.spacing[1],
      paddingBottom: theme.spacing[1],
    },
  },
  tr: {
    ':last-child': {
      td: {
        borderBottom: 0,
      },
    },
  },
  'td:first-child': {
    ...stickyPosition,
    backgroundColor: theme.colors.light.background,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    paddingRight: '10px',
    zIndex: 1,
  },
  'tr.subRow': {
    color: theme.colors.light.text.disabled,
  },
  // 'tr:nth-of-type(even)': {
  //   backgroundColor: theme.colors.light.secondary,
  //   'td:first-child': {
  //     ...stickyPosition,
  //     backgroundColor: theme.colors.light.secondary,
  //   },
  // },
  'tr:hover': {
    ...highlight,
    'td:first-child': {
      ...highlight,
    },
  },
});

export const SLStatTable = ({
  data,
  indexColumns,
  toggleControls,
  highlightCallback,
  cellHoverCallback: cellHoverCallbackArg,
  cellDecorationCallback,
  selectCallback,
  hidePlayoffsToggle,
  defaultShowPlayoffs = false,
  hideTournamentsToggle,
  defaultShowTournaments = false,
  ...rest
}) => {
  const { chartOptions } = useContext(SLStatContext);

  const hasTournaments = data?.data.some(
    (d) => SL_TOURNAMENT_LEAGUE_SLUGS.includes(d.league_slug)
  );

  const {toggled: showTournaments, toggleComponent: tournamentsToggle} = useToggle({
    label: `Show tournaments`,
    initialToggled: defaultShowPlayoffs,
    disabled: !hasTournaments
  })

  // Is there any nhl playoffs data
  const hasPlayoffs =
    data?.data.filter((d) => d.game_type === 'nhl_postseason').length > 0;

  const { toggled: showPlayoffs, toggleComponent: playoffsToggle } = useToggle({
    label: `Show playoffs`,
    initialToggled: defaultShowPlayoffs,
    disabled: !hasPlayoffs,
  });

  // Filter out preseason data
  let filteredData = data?.data.filter(
    (d) =>
      d.game_type !== 'nhl_preseason' &&
      (showPlayoffs || d.game_type !== 'nhl_postseason') &&
      (showTournaments || !SL_TOURNAMENT_LEAGUE_SLUGS.includes(d.league_slug))
  );

  const cellHoverCallback =
    cellHoverCallbackArg ||
    ((label, row, statDef, forceClose) => {
      return (
        <StatCellVideoLinks
          onClick={forceClose}
          statDefinition={statDef}
          statRow={row}
        />
      );
    });

  switch (chartOptions.type) {
    case 'scatter':
      return <StatScatterChart />;
    case 'table':
    default:
      return (
        <TableStyles style={{ overflow: 'auto' }}>
          <Column gap={2}>
            <Row columnGap={4}>
              {!hidePlayoffsToggle && playoffsToggle}
              {!hideTournamentsToggle && tournamentsToggle}
              {toggleControls}
            </Row>
            <BDDStatTableV2
              data={filteredData}
              format={data?.format}
              indexColumns={indexColumns}
              highlight={true}
              highlightCallback={highlightCallback}
              cellDecorationCallback={cellDecorationCallback}
              cellHoverCallback={cellHoverCallback}
              hoverProps={{ delay: { show: 400, hide: 100 } }}
              {...rest}
            />
          </Column>
        </TableStyles>
      );
  }
};
