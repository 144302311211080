import { gql } from "@apollo/client";


const BDDGeneratedResponseFragment = gql`
  fragment bddGeneratedResponseFragment on BDDGeneratedResponses {
    id
    conversationId
    modelId
    config
    prompt
    systemPrompt
    templatedPrompt
    hasError
    error
    generation
    promptTokenCount
    generationTokenCount
    stopReason
    createdAt
    createdByUserId
  }
`;

export const GET_PLAYER_GENERATED_REPORT_SUMMARY = gql`
  query GetPlayerGeneratedReportSummary($rinknetId: ID!) {
    playerGeneratedReportSummary(rinknetId: $rinknetId) {
      id
      isLatest
      rinknetId
      startDate
      endDate
      response {
        ...bddGeneratedResponseFragment
      }
    }
  }
${BDDGeneratedResponseFragment}
`;
