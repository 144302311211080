import { useQuery } from '@apollo/client';
import { GET_STAT_REPORTS_V2 } from 'apollo/queries/statsv2.queries';
import { toastInfo } from 'components/bdd/bddtoasts';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import StatReportExplorerModal from 'components/StatManager/v2/StatReportExplorerModal';
import { useUser } from 'helpers/user';
import { useCallback, useEffect, useState } from 'react';
import EditStatsButton from '../components/EditStatsButton';

/* Component that loads reports based on props
Returns button group to allow quick selecting options
Optionally displays a "More..." button to launch a modal to select from larger set of reports
OR create your own
Returns:
  data, loading, error: response from API with stat reports
  statReportButtons: stat report bg component (includes modal if should be rendered in DOM)
  mode: one of "statReport" or "statDefs" -- reportExplorerModal allows to specify a (temporary) set of statDefs!
  selectedReport: name of report (str) if selected
  selectedStatDefs: statDefs (if mode is statDefs)
  statObj: an object that can be expanded in a query.  Will have a single key, either "statReport" or "statDefs"
    e.g. { statReport: "skatersOverview" } or { statDefs: [{ slug: "toi" }...]}
*/

const TARGET_TO_DEFAULT_REPORT = {
  skaters: 'skatersOverview',
  goalies: 'goaliesOverview',
  teams: 'teamsOverview',
  lines: 'linesOverview',
  linesbyplayer: 'linesOverview',
  pairs: 'pairsOverview',
  pairsbyplayer: 'pairsOverview',
  units: 'linesOverview',
  unitsbyplayer: 'unitsOverview',
};

const unsetLocalDefaultReport = (key) => localStorage.setItem(key, undefined);
const readLocalDefaultReport = (key) => {
  try {
    var localDefaultReport = JSON.parse(localStorage.getItem(key));
    if (!localDefaultReport.slug) unsetLocalDefaultReport(key);
    return localDefaultReport;
  } catch {
    unsetLocalDefaultReport(key);
  }
  return null;
};

export default function useStatReports({
  target,
  hide = [],
  showMoreButton = false,
  showMoreLabel = 'More...',
  storageKeyPrefix,
  defaultReportSlug: defaultReportArg,
  disableAll = false,
  refetchStats,
  skip,
}) {
  // we prefix local storage key differently for different invocations of hook
  const getLocalStorageKey = (target) =>
    `${storageKeyPrefix}-${target}DefaultReport`;

  const localDefaultReport = readLocalDefaultReport(getLocalStorageKey(target));
  const initialDefaultReport = !!defaultReportArg
    ? defaultReportArg
    : !!localDefaultReport
    ? localDefaultReport.slug
    : TARGET_TO_DEFAULT_REPORT[target];

  const [defaultReportSlug, setDefaultReportSlug] =
    useState(initialDefaultReport);

  const initialState = {
    mode: 'statReport',
    statReport: defaultReportSlug,
    statReportDetail: { slug: defaultReportSlug },
    statDefs: null,
    statObj: { statReport: defaultReportSlug },
  };

  const [selected, setSelected] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const { user } = useUser();
  const { data, loading, error } = useQuery(GET_STAT_REPORTS_V2, {
    skip,
    variables: {
      targets: [target],
      hide,
    },
  });

  useEffect(() => {
    if (!!data && !selected.statReportDetail.__typename) {
      // if we have a default stored report that doesn't have any report info stored
      // when we get info, update it
      const srv2 = data.statReportsV2.find(
        (s) => s.slug === selected.statReport
      );
      if (!!srv2) {
        setSelected({
          ...selected,
          statReportDetail: srv2,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (!!data) {
      setDefaultReportSlug(() => initialDefaultReport);
      setSelected(() => ({
        mode: 'statReport',
        statReport: initialDefaultReport,
        statReportDetail: { slug: initialDefaultReport },
        statDefs: null,
        statObj: { statReport: initialDefaultReport },
      }));
    }
  }, [data, target]);

  const remoteReports = !!data
    ? [...data?.statReportsV2]
        .filter((r) => r.isBddReport && r.isPublic)
        .sort((a, b) => b.priority - a.priority)
    : [];
  let allReports = !!localDefaultReport
    ? [localDefaultReport].concat(
        remoteReports.filter((s) => s.slug !== localDefaultReport.slug)
      )
    : remoteReports;
  // if we have a stat report selected but no option for it, add it to the front
  if (
    !!selected.statReportDetail &&
    !allReports.find((r) => r.slug === selected.statReportDetail.slug)
  ) {
    allReports = [selected.statReportDetail].concat(allReports);
  }

  let reportOptions = allReports?.map((s) => ({
    value: s.slug,
    label: !!s.name ? s.name : s.slug,
    priority: s.priorityRank,
    hover: (
      <>
        <div>{s.name}</div>
        {!!s.description && <div>"{s.description}"</div>}
        <div style={{ fontSize: '0.8em' }}>
          {s.isBddReport ? (
            <em>BDD managed report</em>
          ) : (
            !!s.userId && (
              <em>
                This is{' '}
                {user?.id === s.userId ? 'your' : `${s.user.username}'s`} report
              </em>
            )
          )}
        </div>
      </>
    ),
  }));

  const showMoreValue = 'more';
  if (reportOptions.length > 0 && showMoreButton) {
    reportOptions?.push({
      value: showMoreValue,
      label: showMoreLabel,
      priority: -1,
      hover: <div>Explore user reports, or build your own!</div>,
    });
  }

  const { buttonGroup, selectedOption, forceSelectOption } = useButtonGroup({
    initialSelectedValue: defaultReportSlug,
    options: reportOptions,
    width: '100%',
    noWrap: true,
    disabled: disableAll,
  });
  const handleChange = useCallback(
    (mode, value, statReportDetail=null) => {
      // mode can be 'statReport' or 'statDefs'
      // value is a reportSlug or statDefs
      // statReportDetail is the stat report detail object if we've edited a statReport but still need to keep info for BG

      // Change can come from change in selected option from BG
      // OR from callback from modal
      if (!['statReport', 'statDefs'].includes(mode)) {
        throw Error('Unknown mode ' + mode);
      }
      // Find stat report detail (if its loaded up) otherwise we'll have a mostly empty one
      if (mode === 'statReport') {
        statReportDetail = data?.statReportsV2?.find((sr) => sr.slug === value);
        if (!statReportDetail) {
          statReportDetail = { slug: value };
        }
      } else if (mode === 'statDefs') {
        // if this is a modification of one of our default stat reports, don't need to set statReportDetail (will have dup options in BG)
        if (!!allReports.find((sr) => sr.slug === statReportDetail.reportSlug)) {
          statReportDetail = null;
        }
      }

      const newSelected = {
        mode,
        statReport: mode === 'statReport' ? value : null,
        statReportDetail,
        statDefs: mode === 'statDefs' ? value : null,
        statObj:
          mode === 'statReport' ? { statReport: value } : { statDefs: value },
      };
      setSelected(newSelected);
    },
    [setSelected, data]
  );

  useEffect(() => {
    if (selectedOption?.value === showMoreValue) {
      // don't try and fetch "more", that will fail, launch modal
      setShowModal(true);
      return;
    }
    handleChange(
      'statReport',
      !!selectedOption ? selectedOption.value : TARGET_TO_DEFAULT_REPORT[target]
    );
  }, [selectedOption?.value]); // eslint-disable-line

  const handleCloseModal = () => {
    forceSelectOption(selected.statReport);
    setShowModal(false);
  };

  const handleForceSelectReport = (reportSlug) => {
    // if we're "force selecting" a report that's already selected
    // this means we want to force re-load the stats (because they've changed)
    if (selected.mode == 'statReport' && reportSlug === selected.statReport) {
      refetchStats();
    }
    handleChange('statReport', reportSlug);
    forceSelectOption(reportSlug);
  };

  const handleReportCreated = (statReportV2) => {
    toastInfo(
      `"${statReportV2.name || statReportV2.slug}" successfully created`
    );
    handleForceSelectReport(statReportV2.slug);
  };

  const handleSetLocalDefaultReport = () => {
    if (!selected.statReport) return;
    localStorage.setItem(
      getLocalStorageKey(target),
      JSON.stringify(selected.statReportDetail)
    );
    setDefaultReportSlug(selected.statReport);
  };

  const reportExplorerModal = showModal && (
    <StatReportExplorerModal
      show={showModal}
      target={target}
      handleClose={handleCloseModal}
      handleReportCreated={(statReportV2) => {
        handleReportCreated(statReportV2);
        setShowModal(false);
      }}
      handleSelect={(reportSlug) => {
        handleForceSelectReport(reportSlug);
        setShowModal(false);
      }}
      queryVariables={{
        targets: [target],
      }}
    />
  );

  const statReportsButtons = (
    <>
      {reportExplorerModal}
      {buttonGroup}
    </>
  );

  const isDefaultReport =
    selected.mode === 'statReport' && selected.statReport === defaultReportSlug;
  const editStatsButton = (
    <EditStatsButton
      refetchStats={refetchStats} // a "Save"-ed report will require a refetch
      handleFetch={(statDefs, statReportDetail) => handleChange('statDefs', statDefs, statReportDetail)} // a "temporary" report changes the statDefs
      handleReportCreated={(newStatReport) => {
        handleReportCreated(newStatReport);
      }}
      target={target}
      mode={selected.mode}
      statReportDetail={selected.statReportDetail}
      isDefaultReport={isDefaultReport}
      setLocalDefaultReport={handleSetLocalDefaultReport}
      {...selected.statObj}
    />
  );

  return {
    loading,
    error,
    data,
    statReportsButtons,
    editStatsButton,
    reportInfo: selected.mode === 'statReport' ? selectedOption : null,
    ...selected,
    reportOptions,
  };
}
