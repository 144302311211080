import { getMetastatsBySlug } from "helpers/stats"
import React from "react"
import { normalizeVector } from "../../helpers/math"
import { defaultColors, getTeamColorPalette, plotlyColorPalette } from "../../helpers/plotting"
import BDDPlotly from "../bdd/bddplotly"
import { SubSectionHeader } from "../reports"
import regression from 'regression';


export default function GoodBadHighLowScatter({ 
    title, 
    data, 
    metastats, 
    xStat='XPGF', 
    yStat='XPGA',
    xLabel,
    yLabel,
    norm='20', 
    colorArg,
    dataToText,
    invertY=true,
    showLabels=true,
    useImages=false,
    dataToImage=null,
    imSize=null,
    imScale=1, // mult factor to apply to calculated imSize if not supplied
    hideModeBar=false,
    dataToCustomData,
    highlightCallback: highlightCallbackArg=() => false,
    defaultOpacity,
    highlightOpacity,
    dataToHasRocket=() => true,
    useRandomColors=false,
    dataToColor: dataToColorArg,
    onClick=null,
    showBestFit=false,
    idKey='slug', // used to uniquely identify a data row.  Necessary for rockets
    rockets=null, // Should be a [{}] like data.  If supplied, will draw "rockets" for every entity
    topRightLabel='Good',
    bottomRightLabel='High Activity',
    topLeftLabel='Low Activity',
    bottomLeftLabel='Bad',
    annotationProps: annotationPropsArg = {}
}) {

    if (!xLabel) xLabel = xStat;
    if (!yLabel) yLabel = yStat;
    const highlightCallback = !!highlightCallbackArg ? highlightCallbackArg : () => false;
    const color = colorArg ? colorArg : defaultColors[0]
    // if "useRandomColors" then must make sure same color is used for same player on rockets as well
    if (useRandomColors) {
        const idToColor = {}
        const xOrdered = [...data].sort((a,b) => a[xLabel] - b[yLabel])
        xOrdered.forEach((d, i) => idToColor[d[idKey]] = plotlyColorPalette[i % plotlyColorPalette.length])
        var dataToColor = d => idToColor[d[idKey]]
    } else {
        var dataToColor = !!dataToColorArg ? dataToColorArg : () => color
    }
    const annotationProps = {
      xref: 'paper', yref: 'paper',
      showarrow: false,
      ...annotationPropsArg,
      font: {
          font: 'Arial bold',
          color: '#933',
          size: 12,
          ...annotationPropsArg?.font
      },
    }
    const avgLineProps = {
        type: 'line',
        opacity: 0.5,
        layer: 'below',
        line: {
            color: '#333',
            width: 2,
            dash: 'dot'
        }
    }

    if (!imSize) {
        const xrange = [Infinity, -Infinity]
        const yrange= [Infinity, -Infinity]
        data.forEach(d => {
            xrange[0] = Math.min(d[xLabel], xrange[0])
            xrange[1] = Math.max(d[xLabel], xrange[1])
            yrange[0] = Math.min(d[yLabel], yrange[0])
            yrange[1] = Math.max(d[yLabel], yrange[1])
        })
        const avgRange = ((xrange[1] - xrange[0]) + (yrange[1] - yrange[0])) / 2
        var imSize = avgRange * 0.16 * imScale // a rough approx to get images appropriately sized
    }

    // if a highlight callback is supplied, sort so highlighted items drawn last
    var sortedData = useImages && !!highlightCallback ? 
        [...data].sort((a,b) => highlightCallback(a) - highlightCallback(b))
        : data


    const nonHighlightedData = !!highlightCallback ? data.filter(d => !highlightCallback(d)) : data
    const highlightedData = !!highlightCallback ? data.filter(d => highlightCallback(d)) : []

    if (!defaultOpacity) {
      if (useImages) {
        defaultOpacity = !!highlightedData.length ? !!rockets ? 0.5 : 0.3 : 0.7;
      } else {
        defaultOpacity = !!highlightedData.length ? 0.4 : 0.5;
      }
    }
    if (!highlightOpacity) {
      if (useImages) {
        highlightOpacity = 0.9;
      } else {
        highlightOpacity = 0.6;
      }
    }

    const defaultDSize = 20
    const pdata = []
    var images = []
    var shapes = []

    // ROCKETS
    if (!!rockets) {
        const idToPrev = {} // rocket start point
        rockets.forEach(d => idToPrev[d[idKey]] = d)
        const idToCurr = {} // rocket end point
        data.forEach(d => idToCurr[d[idKey]] = d)
        // Plot "rockets"
        sortedData.forEach(d => {
            const id = d[idKey]
            if (!(id in idToPrev)) return
            if (!dataToHasRocket(idToPrev[id], idToCurr[id])) return
            const { [xLabel]: x1, [yLabel]: y1} = idToPrev[id]
            const { [xLabel]: x2, [yLabel]: y2} = idToCurr[id]
            pdata.push({
                name: id,
                mode: 'lines',
                x: [x1, x2],
                y: [y1, y2],
                opacity: highlightCallback(d) ? highlightOpacity : defaultOpacity,
                line: { 
                    color: dataToColor(d),
                    width: 5
                },
                hoverinfo: 'skip'
            })
        })
        
    }

    // Plot non-highlighted data markers
    nonHighlightedData.forEach(d => {
        pdata.push({
            mode: showLabels ? 'markers+text' : 'markers',
            x: [d[xLabel]],
            y: [d[yLabel]],
            text: dataToText ? [dataToText(d)] : null,
            extraData: [d],
            customdata: dataToCustomData ? [dataToCustomData(d)] : [''],
            marker: { 
                color: dataToColor(d), 
                size: defaultDSize, 
                opacity: useImages ? 0 : defaultOpacity },
            hovertemplate: "<b>%{text}</b><br>" +
            "%{yaxis.title.text}: %{y:.2f}<br>" +
            "%{xaxis.title.text}: %{x:.2f}<br>" +
            "%{customdata}<br>" +
            "<extra></extra>"
        })
    })

    // Plot highlighted data markers
    if (highlightedData.length) {
        highlightedData.forEach(d => {
            pdata.push({
                showlegend: false,
                mode: showLabels ? 'markers+text' : 'markers',
                x: [d[xLabel]],
                y: [d[yLabel]],
                text: dataToText ? [dataToText(d)] : null,
                extraData: [d],
                customdata: dataToCustomData ? [dataToCustomData(d)] : [''],
                marker: { 
                    color: dataToColor(d), 
                    size: defaultDSize*1.5, 
                    opacity: useImages ? 0 : highlightOpacity },
                hovertemplate: "<b>%{text}</b><br>" +
                "%{yaxis.title.text}: %{y:.2f}<br>" +
                "%{xaxis.title.text}: %{x:.2f}<br>" +
                "%{customdata}<br>" +
                "<extra></extra>"
            })
        })
    }

    // Logos
    images = images.concat(useImages ? sortedData.map(d => {
        const isHighlighted = highlightCallback(d)
        const size = isHighlighted ? imSize * 1.2 : imSize
        return {
            source: dataToImage(d),
            xref: "x", yref: "y",
            x: d[xLabel],
            y: d[yLabel],
            sizex: size,
            sizey: size,
            opacity: isHighlighted ? highlightOpacity : defaultOpacity,
            xanchor: "center",
            yanchor: "middle",
            below: 'traces'
        }
    }) : [])
    const xMetas = getMetastatsBySlug(metastats, xStat)
    const yMetas = getMetastatsBySlug(metastats, yStat)
    shapes = shapes.concat(!metastats || Object.keys(metastats).length === 0 ? [] : [{
            xref: 'paper', yref: 'y',
            x0: 0, x1: 1,
            y0: yMetas.mean,
            y1: yMetas.mean,
            ...avgLineProps
        }, {
            xref: 'x', yref: 'paper',
            x0: xMetas.mean,
            x1: xMetas.mean,
            y0: 0, y1: 1,
            ...avgLineProps
        }])

    if (showBestFit) {
      const xyPairs = data.map((d) => [d[xLabel], d[yLabel]]);
      const regResult = regression.linear(xyPairs);
      const minX = Math.min(...data.map((d) => d[xLabel]));
      const maxX = Math.max(...data.map((d) => d[xLabel]));
      pdata.push({
        mode: 'lines',
        name: 'Best Fit',
        x: [minX, maxX],
        y: [regResult.predict(minX)[1], regResult.predict(maxX)[1]],
        line: { dash: 'dot', color: 'black' },
      });
    }

    return <>
    <SubSectionHeader>
        {title}
    </SubSectionHeader>
    <BDDPlotly
        data={pdata}
        layout={{
            showlegend: false,
            margin: { t: 30 },
            xaxis: { 
                title: `${xLabel}${norm ? `/${norm}` : null}`,
                zeroline: false
            },
            yaxis: {
                title: `${yLabel}${norm ? `/${norm}` : null}`,
                autorange: invertY ? 'reversed' : null,
                zeroline: false
            },
            hovermode: 'closest',
            hoverlabel: { bgcolor: "#FFF" },
            images,
            annotations: [{
                x: 1, y: 1,
                text: `<b>${topRightLabel}</b>`,
                ...annotationProps
            }, {
                x: 0, y: 0,
                text: `<b>${bottomLeftLabel}</b>`,
                ...annotationProps
            }, {
                x: 1, y: 0,
                text: `<b>${bottomRightLabel}</b>`,
                ...annotationProps
            }, {
                x: 0, y: 1,
                text: `<b>${topLeftLabel}</b>`,
                ...annotationProps
            }],
            shapes: shapes
        }}
        config={hideModeBar ? { displayModeBar: false } : undefined}
        onClick={onClick}
        trackResizeOnRender={true}
    />
    </>
}