import { FindTargetsByTeam } from "components/ProObjectives/Targets/FindTargetsByTeam";
import { IconButton, LinkButton } from "components/bdd/Button";
import { Card } from "components/bdd/Card"
import { Divider } from "components/bdd/Divider";
import { Border, Column, Container, Row } from "components/bdd/Layout"
import useTeamSelect from "components/bdd/Select/useTeamSelect"
import { Typography } from "components/bdd/Typography";
import { BDDLoader } from "components/bdd/bddloader";
import { useContext, useEffect, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { PlusCircle, XLg } from "react-bootstrap-icons";
import styled from "styled-components";
import { useInteractiveDC } from "../DepthCharts/InteractiveDC";
import TeamDraftCaptal from "components/TeamsV2/DraftPicks/TeamDraftCapital";
import { useButtonGroup } from "components/bdd/ButtonGroup";
import BDDDepthProspectLists from "../BDDDepthProspectLists";
import { BDD_ASSET_TYPES } from "./constants";
import BDDTradeAsset from "./BDDTradeAsset";
import { TradeAssetSearch } from "./TradeAssetSearch";
import produce from "immer";
import Icon from "components/bdd/Icon";
import useInnerScroll from "components/bdd/hooks/useInnerScroll";
import { WindowContext } from "components/bdd/context";
import { theme } from "constants";



const AssetSelector = ({ 
  teamSlug,
  forceDepthChartId,
  sending,
  handleAddAsset,
  handleRemoveAsset,
}) => {
  
  const handleToggleAsset = (assetType, assetId, extra) => {
    if (!sending.find(s => s.assetType === assetType && s.assetId == assetId)) {
      handleAddAsset(assetType, assetId, extra)
    } else {
      handleRemoveAsset(assetType, assetId)
    }
  }

  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: 'depth',
    options: [
      { label: 'Depth Chart', value: 'depth' },
      { label: 'Prospects', value: 'prospects' },
      { label: 'Draft Picks', value: 'picks' },
      { label: 'Search', value: 'search' },
    ]
  });

  const { depthChart } = useInteractiveDC({
    teamSlug: teamSlug,
    depthChartId: forceDepthChartId,
    playerIsSelected: (bddPlayer) => sending.find(s => s.assetId === bddPlayer.slug),
    handlePlayerClick: (slug, bddPlayer) => handleToggleAsset(BDD_ASSET_TYPES.PLAYER, slug, { bddPlayer }),
  })
  
  return <Column>
    {buttonGroup}
    {selectedValue === 'depth'
      ? (
        depthChart
      ) : selectedValue === 'picks'
      ? (
        <TeamDraftCaptal 
          teamSlug={teamSlug}
          showAllButton={true}
          showFutureOnly={true}
          onClick={(pick, isOwned) => {
            if (isOwned) {
              handleToggleAsset(BDD_ASSET_TYPES.DRAFT_PICK, pick.id, { bfDraftPick: pick })}
            }
          }
          selectedPicks={sending.filter(s => s.assetType === BDD_ASSET_TYPES.DRAFT_PICK).map(p => p.assetId)}
        />
      ) : selectedValue === 'prospects'
      ? (
        <BDDDepthProspectLists
          activeTeamSlug={teamSlug}
          editable={false}
          showHeader={false}
          onPlayerClick={(slug, listPlayer) => handleToggleAsset(BDD_ASSET_TYPES.PLAYER, slug, { bddPlayer: listPlayer.bddPlayer })}
          playerFields={`
            bddPlayer {
              slug
              firstname
              lastname
              bfSlug              
            }
          `}
        />
      ) : selectedValue === 'search'
      ? (
        <Container padding={2}>
          <TradeAssetSearch teamSlug={teamSlug} handleToggleAsset={handleToggleAsset} />
        </Container>
      ) : null}
  </Column>

}

const ExpandableContainer = styled.div({
  boxShadow: '0 0 5px inset #777'
})

const TradeTeam = ({ 
  teamSlug,
  forceDepthChartId,
  handleChangeTeam, 
  assets=[],
  handleAddAsset,
  handleRemoveAsset,
  handleUpdateAsset,
  multiTeam=false,
  handleRemoveTeam, 
  defaultExpand=true
}) => {
  const receiving = assets.filter(a => a.toTeamSlug === teamSlug);
  const sending = assets.filter(a => a.fromTeamSlug === teamSlug);
  const [expand, setExpand] = useState(defaultExpand);
  const { select, selectedValue } = useTeamSelect({ 
    initialSelectedValue: teamSlug,
    leagueSlug: 'NHL',
    variant: 'outlined',
    selectProps: {
      isClearable: false,
      width: 200
    }
  });
  useEffect(() => {
    if (!!selectedValue && selectedValue !== teamSlug) {
      handleChangeTeam(selectedValue);
    }
  }, [selectedValue])

  const { buttonGroup } = useButtonGroup({
    variant: 'link',
    options: [
      { label: 'Select Assets', value: 'assets' },
      { label: 'Analyze', value: 'analysis' },
    ]
  })

  return (
    <Border borderRadius={2} style={{ height: '100%', minHeight: '200px' }}>
      <Container padding={2}>
        <Column gap={2}>
          <Row justifyContent='space-between'>
            <Container width={200}>
              {select}
            </Container>
            {!!multiTeam && (
              <IconButton icon={<XLg/>} onClick={handleRemoveTeam} tooltip={'Remove Team'} />
            )}
          </Row>

          <Divider />

          <Container>
            <Typography variant='label'>Assets to receive</Typography>
            <Column gap={0} style={{ minHeight: '50px' }}>
                {receiving.map(a => (
                  <Row key={a.assetId}>
                    {'-'}
                    <BDDTradeAsset 
                      asset={a} 
                      setAsset={asset => handleUpdateAsset(a.assetId, asset)} 
                      handleRemove={() => handleRemoveAsset(a.assetType, a.assetId)}
                    />
                  </Row>
                ))}
            </Column>
          </Container>

          <Container>
            <Typography variant='label'>Assets to send away</Typography>
            <Column gap={0} style={{ minHeight: '50px' }}>
              {sending.map(a => (
                <Row key={a.assetId} gap={2}>
                  {'-'}
                  <BDDTradeAsset 
                    allowRetention={true} 
                    allowConditions={true}
                    asset={a} 
                    setAsset={asset => handleUpdateAsset(a.assetId, asset)} 
                    handleRemove={() => handleRemoveAsset(a.assetType, a.assetId)}
                  />
                </Row>
              ))}
            </Column>
          </Container>

          <Divider/>

          <Container>
            <Column gap={1}>
              <Row>
                <LinkButton onClick={() => setExpand(!expand)}>
                  {!!expand ? 'Hide Assets' : 'Add Assets'}
                </LinkButton>
              </Row>
              {/* {buttonGroup} */}
              <Collapse in={expand}>
                <ExpandableContainer>
                  <AssetSelector
                    teamSlug={teamSlug}
                    forceDepthChartId={forceDepthChartId}
                    sending={sending}
                    handleAddAsset={handleAddAsset}
                    handleRemoveAsset={handleRemoveAsset}
                  />
                </ExpandableContainer>
              </Collapse>
            </Column>
          </Container>
        </Column>
      </Container>
    </Border>
  ) 
}

export const BDDTradeForm = ({
  teams,
  setTeams,
  assets, // 
  setAssets,
  showSubmit,
  handleSubmit,
  submitting,
  teamDepthChartIds, // [{ teamSlug, depthChartId }] // if supplied will use this DCID instead of the active
}) => {
  const { width } = useContext(WindowContext);

  const { renderScrollableRow } = useInnerScroll({
    rowProps: { gap: 4 },
    externalTrigger: teams?.length
  });
  const handleChangeTeam = (oldTeamSlug, newTeamSlug) => {
    setTeams(teams.map(t => t === oldTeamSlug ? newTeamSlug : t));
    setAssets(assets
      .filter((a) => a.fromTeamSlug !== oldTeamSlug)
      .map((a) => a.toTeamSlug === oldTeamSlug ? { ...a, toTeamSlug: newTeamSlug } : a));
  }

  const { bddTeams } = useTeamSelect({ 
    initialSelectedValue: null,
    leagueSlug: 'NHL',
  });
  const handleAddTeam = () => {
    setTeams(teams.concat(bddTeams.find(t => !teams.includes(t.slTeamSlug)).slTeamSlug))
  }
  
  const handleRemoveTeam = (teamSlug) => {
    setAssets(assets.filter(a => a.fromTeamSlug !== teamSlug && a.toTeamSlug !== teamSlug));
    setTeams(teams.filter(t => t !== teamSlug));
  }

  const renderTeam = (t) => {
    const defaultOtherTeamSlug = teams.find(team => team !== t); 
    return (
      <Container key={t} flexGrow={1} flexBasis={0} minWidth={350}>
        <TradeTeam
          teamSlug={t}
          forceDepthChartId={teamDepthChartIds?.find(d => d.teamSlug == t)?.depthChartId}
          handleChangeTeam={(newTeamSlug) => handleChangeTeam(t, newTeamSlug)}
          handleRemoveTeam={() => handleRemoveTeam(t)}
          multiTeam={teams.length > 2}
          assets={assets}
          handleAddAsset={(assetType, assetId, extra) => setAssets(
            assets.concat([{
              id: `${assetType}-${assetId}`,
              fromTeamSlug: t,
              toTeamSlug: defaultOtherTeamSlug,
              assetType,
              assetId,
              retainedSalary: 0,
              retainedSalaryPct: 0,
              isConditional: false,
              conditionalDetails: null,
              ...extra              
            }])
          )}
          handleRemoveAsset={(assetType, assetId) => {
            setAssets(assets.filter(a => !(a.assetType === assetType && a.assetId == assetId)))
          }}
          handleUpdateAsset={(assetId, newAsset) => {
            setAssets(
              produce(
                assets, (draft) => {
                  const idxToUpdate = assets.findIndex(a => a.assetId === assetId);
                  draft.splice(idxToUpdate, 1, newAsset)
                }
              )
            );
          }}
        />
      </Container>
    )
  }

  return <Container>
  
    <Column gap={1}>
      <Row justifyContent='end'>
        <LinkButton onClick={handleAddTeam} disabled={!bddTeams}>
          <Row gap={2}>
            <Icon icon={<PlusCircle/>}/>
            <Typography variant='body2'>Add Team</Typography>
          </Row>
        </LinkButton>
      </Row>

      <Container>
        {width < (theme.breakpoints.sm + theme.breakpoints.md)/2
          ? (
            <Column>
              {teams.map(t => renderTeam(t))}
            </Column>
          ) : renderScrollableRow(teams.map(t => renderTeam(t)))
        }
      </Container>
      
      {showSubmit && <Row justifyContent='center'>
        <Button block variant='warning' disabled={assets.length === 0 || submitting} onClick={() => handleSubmit(teams, assets)}>
          {submitting ? 'Saving...' : 'Save Trade'}
        </Button> 
      </Row>}
    </Column>
  </Container>
}