import { useQuery } from '@apollo/client';
import { GET_STAT_DEFINITION_V2 } from 'apollo/queries/statsv2.queries';
import {
  shotChanceOptions,
  shotLocationOptions,
  shotQualityOptions,
  shotTypeOptions,
  applyPlayerFilter,
  applySecondaryPlayerFilter,
} from '..';
import { shotOutcomeOptions } from '../helpers';

const isTimeStat = (statDefinition) => {
  if (!statDefinition || !statDefinition.kwargs) return false;
  const parsedKeyArgs = JSON.parse(statDefinition.kwargs);
  return parsedKeyArgs.is_time_stat;
};

const addFilterFromStatDef = (
  filters,
  { statArgs, id, col, cond, options, keepIfEmpty }
) => {
  let definitionValues = statArgs
    .map((sa) =>
      sa[col] instanceof Object
        ? sa[col] instanceof Array
          ? sa[col]
          : sa[col].val
        : sa[col]
    )
    .flat()
    .filter((v) => !!v);

  if (options) {
    definitionValues = definitionValues.filter((v) => options.find((o) => o.value == v));
  }

  const values =
    definitionValues.length > 0
      ? definitionValues
      : keepIfEmpty
      ? filters[id || col]?.val
      : null;

  // Special case for event flags,
  // since it's been split in the UI by separate ids (shot_type, shot_chance, etc...)
  if (id == 'event_flags' && values?.length > 0) {
    const groupedValues = values.reduce((grouped, value) => {
      const id = getFilterIdFromEventFlag(value);
      if (!(id in grouped)) {
        grouped[id] = [];
      }
      grouped[id].push(value);
      return grouped;
    }, {});

    Object.entries(groupedValues).forEach(([id, values]) => {
      filters[id || col] = {
        id: id || col,
        col: col,
        cond: 'includes_any',
        val: values,
      };
    });
  } else {
    filters[id || col] = {
      id: id || col,
      col: col,
      val: values,
    };

    if (values?.length > 0) {
      filters[id || col].cond = cond;
    }
  }
};

const getFilterIdFromEventFlag = (eventFlag) => {
  if (shotTypeOptions.map((o) => o.value).includes(eventFlag)) {
    return 'shot_type';
  }
  if (shotQualityOptions.map((o) => o.value).includes(eventFlag)) {
    return 'shot_quality';
  }
  if (shotLocationOptions.map((o) => o.value).includes(eventFlag)) {
    return 'shot_location';
  }
  if (shotChanceOptions.map((o) => o.value).includes(eventFlag)) {
    return 'shot_chance';
  }
  if (shotOutcomeOptions.map((o) => o.value).includes(eventFlag)) {
    return 'shot_outcome';
  }
};

export const useStatDefinitionFilters = ({ slug }) => {
  const { data, loading } = useQuery(GET_STAT_DEFINITION_V2, {
    skip: !slug,
    variables: {
      slug,
    },
  });

  const statDefinition = data?.statDefinitionV2;
  const statArgs = statDefinition && JSON.parse(statDefinition.statArgs);
  const kwargs = statDefinition && JSON.parse(statDefinition.kwargs || {});

  const getStatDefinitionFilters = (slPlayer, filters, secondaryPlayerFilters) => {
    if (!statDefinition) return;

    return statArgs.reduce((statFilters, statArg) => {
      let updatedFilters = { ...filters };
      const statArgs = [statArg];

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'event_name',
        cond: 'isin',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'outcome',
        cond: 'includes_substr',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'zone',
        cond: 'includes_substr',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'location',
        cond: 'includes_substr',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'event_type',
        cond: 'includes_substr',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'strength',
        cond: '~',
        keepIfEmpty: true,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        col: 'manpower_situation',
        cond: '~',
        keepIfEmpty: true,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'shot_type',
        col: 'event_flags',
        cond: 'includes_any',
        options: shotTypeOptions,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'shot_quality',
        col: 'event_flags',
        cond: 'includes_any',
        options: shotQualityOptions,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'shot_location',
        col: 'event_flags',
        cond: 'includes_any',
        options: shotLocationOptions,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'shot_chance',
        col: 'event_flags',
        cond: 'includes_any',
        options: shotChanceOptions,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'shot_outcome',
        col: 'event_flags',
        cond: 'includes_any',
        options: shotOutcomeOptions,
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'event_flags',
        col: 'event_flags',
        cond: 'includes_any',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'preceding_flags',
        col: 'preceding_flags',
        cond: 'includes_any',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'successive_flags',
        col: 'successive_flags',
        cond: 'includes_any',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'location_2',
        col: 'location_2',
        cond: 'includes_substr',
      });

      addFilterFromStatDef(updatedFilters, {
        statArgs,
        id: 'side',
        col: 'side',
        cond: 'includes_substr',
      });

      const eventForToggled = statArgs[0].event_for;
      const onIceToggled = statArgs[0].on_ice || kwargs['on_ice_only'];

      let involvement;
      // Determine player involvment
      if (!!slPlayer) {
        if (!onIceToggled) {
          involvement = 'primary';
        } else if (eventForToggled && onIceToggled) {
          involvement = 'woi';
        } else if (!eventForToggled && onIceToggled) {
          involvement = 'woia';
        }
      }
      // Determine team involvement
      else {
        if (eventForToggled && onIceToggled) {
          involvement = 'woi';
        } else if (!eventForToggled && onIceToggled) {
          involvement = 'woia';
        }
      }

      updatedFilters = applyPlayerFilter(slPlayer, involvement, updatedFilters);

      if (secondaryPlayerFilters) {
        updatedFilters = applySecondaryPlayerFilter({
          filters: updatedFilters,
          involvement,
          secondaryPlayerFilters,
        });
      }

      statFilters.push(updatedFilters);
      return statFilters;
    }, []);
  };

  return {
    loading,
    statDefinition,
    getStatDefinitionFilters,
  };
};
