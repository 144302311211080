import { useQuery } from "@apollo/client";
import { useQueryWithPlaceholder } from "apollo/hooks";
import { GET_STAT_REPORTS_V2, GET_STAT_REPORT_V2 } from "apollo/queries/statsv2.queries";
import BDDApiError from "components/bdd/bddapierror"
import { BDDLoader } from "components/bdd/bddloader"
import { useButtonGroup } from "components/bdd/ButtonGroup";
import { Row as BDDRow } from "components/bdd/Layout";
import { useSelect } from "components/bdd/Select";
import TruncatedText from "components/bdd/Truncated/TruncatedText";
import { TooltipSpan } from "components/reports";
import { theme } from "constants";
import { filterSetToString } from "helpers/hockeyutils";
import { useUser } from "helpers/user";
import { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ArrowLeft, ArrowLeftCircle } from "react-bootstrap-icons";
import styled from "styled-components";
import CreateUpdateStatReport from "./CreateUpdateStatReport";
import { describeReportOwnership } from "./helpers";


const Container = styled.div({})


export const ScrollableOptions = styled.div({
  maxHeight: '330px',
  overflow: 'auto'
})

const StatReportOptionStyles = styled.div({
  ...theme.typography.body1,
  padding: theme.spacing[1],
  marginTop: theme.spacing[1],
  cursor: 'pointer',
  borderRadius: '5px',
  border: `1px solid #333`,
  background: ({ selected }) => !!selected ? '#333' : 'white',
  color: ({ selected }) => !!selected ? '#ddd' : '#333'
})
const StatReportOptionsCaption = styled.div({
  ...theme.typography.caption
})

export const StatReportOption = ({ report, selected, handleSelect }) => {
  const { user } = useUser()
  return <StatReportOptionStyles selected={selected} onClick={handleSelect}>
    {report.name}
    <StatReportOptionsCaption>
      <div>
        {!!report.description && <TruncatedText maxWidth='100%'>{report.description}</TruncatedText>}
      </div>
      {describeReportOwnership(report, user)}
    </StatReportOptionsCaption>
  </StatReportOptionStyles>
}

const ReportName = styled.div({
  ...theme.typography.h6
})
const ReportDescription = styled.p({
  ...theme.typography.body2,
  marginBottom: theme.spacing[1]
})
const StatList = styled.ol({
  ...theme.typography.body2,
  maxHeight: '300px',
  overflow: 'auto'
})
const Label = styled.label({
  ...theme.typography.caption,
  margin: 0
})
const ButtonsContainer = styled.div({
  display: 'flex',
  gap: theme.spacing[2],
  justifyContent: 'flex-end'
})
const A = styled.button({
  ...theme.typography.body1,
  float: 'right',
  border: 0,
  background: 0,
  ':hover': {
    textDecoration: 'underline'
  }
})

export const StatReportDetail = ({ 
  allowEdit=true,
  reportSlug, 
  handleEditReport, 
  handleSelect,
  selectButtonText='Fetch'
}) => {
  const { data, placeholder } = useQueryWithPlaceholder(GET_STAT_REPORT_V2, { slug: reportSlug, loadStatDefinitions: true })
  const { user, isUserAdmin } = useUser();
  const report = data?.statReportV2
  const canEditReport = report?.isPublicEdit || isUserAdmin() || report?.userId == user.id

  return (
    <>
      {!!placeholder ? (
        placeholder
      ) : (
        <>
          <ReportName>{report.name || report.slug}</ReportName>
          <ReportDescription>{report.description}</ReportDescription>

          <StatList>
            {report.stats.map((s) => (
              <li key={`${s.statSlug}-${s.filterSetId}-${s.norm}`}>
                <BDDRow columnGap={2} flexWrap>
                  <div>
                    <Label>slug:</Label>{" "}
                    <TooltipSpan content={s.stat.description}>
                      {s.statSlug}
                    </TooltipSpan>
                  </div>
                  {s.label !== s.statSlug && (
                    <div>
                      <Label>label:</Label> "{s.label}"
                    </div>
                  )}
                  {!!s.filters && (
                    <div>
                      <Label>filters:</Label>
                      {!!s.filterSet ? filterSetToString(s.filterSet) : ""}
                    </div>
                  )}
                  {!!s.norm && (
                    <div>
                      <Label>norm:</Label>
                      per {s.norm}
                    </div>
                  )}
                </BDDRow>
              </li>
            ))}
          </StatList>
          <ButtonsContainer>
            {allowEdit && canEditReport && (
              <A onClick={handleEditReport}>Edit Report</A>
            )}
            <Button
              size="sm"
              variant="warning"
              onClick={() => handleSelect(reportSlug, report)}
            >
              {selectButtonText}
            </Button>
          </ButtonsContainer>
        </>
      )}
    </>
  );
}

const HeaderRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
})
const Header = styled.div({
  ...theme.typography.h6
})
export const EditStatReport = ({ reportSlug, handleBack, handleSelect }) => {
  const { data, placeholder } = useQueryWithPlaceholder(GET_STAT_REPORT_V2, { slug: reportSlug, loadStatDefinitions: true })
  if (placeholder) return placeholder

  return <Container>
    <HeaderRow>
      <div style={{fontSize: '1.5em', cursor: 'pointer'}}>
        <ArrowLeftCircle onClick={handleBack}/>
      </div>
      <Header>Update {data.statReportV2.name || reportSlug}</Header>
      <div></div>
    </HeaderRow>
    <CreateUpdateStatReport
      noHeader
      isUpdate={true}
      defaultReport={data.statReportV2}
      defaultStats={data.statReportV2.stats}
      onCompleted={statReportV2 => handleSelect(statReportV2.slug)}
    />
  </Container>
}


export default function StatReportSelector({
  queryVariables,
  handleSelect,
  allowEdit=true,
  selectButtonText='Fetch',
  defaultTarget, // used when multiple targets are available
}) {
  const { data, loading, error } = useQuery(GET_STAT_REPORTS_V2, { variables: queryVariables })
  const { user } = useUser();
  const [selected, setSelected] = useState(undefined)
  const [edit, setEdit] = useState(null)
  const { buttonGroup: selectMode, selectedOption } = useButtonGroup({
    options: [
      { value: 'bdd', label: "BDD Reports" },
      { value: 'user', label: "User Reports" },
      { value: 'mine', label: "My Reports" },
    ],
    initialSelectedValue: 'bdd'
  })
  const mode = selectedOption?.value

  const targets = Array.from(new Set(data?.statReportsV2?.filter(r => !!r.target && r.target.length).map(r => r.target)))
  const { buttonGroup: targetButtons, selectedValue: selectedTarget } = useButtonGroup({
    options: targets.map(t => ({ label: t, value: t })),
    initialSelectedValue: defaultTarget
  })

  const filteredReports = data?.statReportsV2.filter(r => {
    if (targets.length > 1 && r.target !== selectedTarget) return false
    if (mode === 'bdd') return r.isBddReport
    if (mode === 'user') return !r.isBddReport
    if (mode === 'mine') return r.userId == user.id
    return true
  }).sort((a, b) => {
    if (mode === 'bdd') return b.priorityRank - a.priorityRank
    if (mode === 'user') return b.user?.username.toLowerCase() < a.user?.username.toLowerCase() ? 1 : -1
    if (mode === 'mine') return new Date(b.lastModified) - new Date(a.lastModified)
  })

  // when data is loaded or mode changes, set report to first in filtered list
  useEffect(() => {
    if (!!data && !!filteredReports.length) setSelected(filteredReports[0].slug)
  }, [data, mode]) // eslint-disable-line

  // if target changes, set report to first in filtered list
  useEffect(() => {
    if (!!data && !!filteredReports.length) setSelected(filteredReports[0].slug)
  }, [data, selectedTarget]) // eslint-disable-line

  return <Container>
    {loading ? <BDDLoader variant="squares"/>
    : error ? <BDDApiError error={error}/>
    : edit ? <EditStatReport reportSlug={edit} handleSelect={handleSelect} handleBack={() => setEdit(null)}/>
    : <div>
      <div style={{padding: '10px 0'}}>
        {targets.length > 1 ? targetButtons : <em>Showing {targets[0]} reports</em>}
      </div>
      <div style={{padding: '10px 0'}}>
        {selectMode}
      </div>
      <Row>
        <Col md={4}>
          <ScrollableOptions>
            {filteredReports.map(r => 
              <StatReportOption key={r.slug}
                selected={r.slug === selected} 
                report={r}
                handleSelect={() => setSelected(r.slug)}
              />)}
          </ScrollableOptions>
        </Col>
        <Col style={{ height: '400px', overflow: 'auto' }}>
          {!!selected && <StatReportDetail 
            reportSlug={selected} 
            allowEdit={allowEdit}
            selectButtonText={selectButtonText}
            handleEditReport={() => setEdit(selected)}
            handleSelect={handleSelect}
          />}
        </Col>
      </Row>
    </div>}
  </Container>
}
